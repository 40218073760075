import React from "react"
import { Link } from "gatsby"
import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const RetouchPage = () => (
  <Layout>
    <SEO title="古いお写真の修整" />
    <div
      className="flex h-[20vh] bg-cover bg-center bg-opacity-25 justify-center content-center"
      style={{ backgroundImage: `url('../retouch-header.webp')` }}
    >
      <p className="text-white text-center text-3xl lg:text-4xl self-center">古いお写真の修整</p>
    </div>
    
    <div className="container space-y-5 px-5 lg:px-0 my-8 h-30 max-w-screen-md justify-center content-center">
      <div className="space-y-5">
        <p className="text-textblack text-2xl text-center">昔のお写真を修整できます！</p>
        <p className="text-textblack text-center">
          昔の色あせたり、折れてしまったり、傷ついてしまったりしてしまったお写真はありませんか？<br/>
          アライヤ写真館では、高度なデジタル修整技術により昔のお写真を修整することが出来ます。
        </p>
        <p className="text-textblack text-center">
          きれいな状態でデータ化することで、何年たっても劣化することはなくなります。
        </p>
        <p className="text-textblack text-center">
          昔のお写真から遺影写真を作成することも出来ます。
        </p>

        <p className="text-textblack text-lg text-center">
          その他にも様々な修整をすることが出来ます。詳しくは<Link to="/#contact">お問い合わせ</Link>ください。
        </p>

        <div className="mx-auto flex flex-row w-2/3 gap-2 justify-center content-center">
          <StaticImage
            className="flex-initial basis-1/2"
            src="../images/retouch/retouch-1.webp"
          />
          <StaticImage
            className="flex-initial basis-1/2"
            src="../images/retouch/retouch-2.webp"
            />
        </div>
        <div className="flex flex-row gap-2">
          <StaticImage
            className="flex-initial basis-1/2"
            src="../images/retouch/retouch-3.webp"
          />
          <StaticImage
            className="flex-initial basis-1/2"
            src="../images/retouch/retouch-4.webp"
          />
        </div>
      </div>
    </div>
  </Layout>
)

export default RetouchPage